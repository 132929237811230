import React, { useState } from "react"

function FaqItem({ question, answer, isOpen, onClick }) {
  return (
    <div className="border-b border-gray-300 pb-4">
      <button 
        onClick={onClick}
        className="flex items-start md:items-center justify-between w-full text-lg focus:outline-none text-left"
        aria-expanded={isOpen}
      >
        <span 
          className={`font-heading pr-4 ${isOpen ? 'font-bold text-gray-900' : 'text-gray-700'}`}
        >
          {question}
        </span>
        <span className="text-gray-500" aria-hidden="true">
          {isOpen ? '-' : '+'}
        </span>
      </button>
      <div 
        style={{ height: isOpen ? 'auto' : '2px' }}
        className="overflow-hidden"
      >
        <div 
          className={`text-gray-700 text-base max-w-screen-md transition-all duration-300 ease-in-out ${
            isOpen ? 'max-h-[500px] opacity-100 pt-4' : 'max-h-0 opacity-0'
          }`}
        >
          {answer}
        </div>
      </div>
    </div>
  )
}

function FAQ({ faqs }) {
  const [selectedIndex, setSelectedIndex] = useState(null)

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  }

  return (
    <section className="relative py-20">
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
      />
      <div className="container mx-auto px-4">
        <div className="space-y-4">
          <div className="mb-8">
          <h2 className="text-3xl font-heading text-gray-800 text-left">
              Frequently Asked Questions
            </h2>
          </div>
          {faqs.map((faq, index) => (
            <FaqItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={selectedIndex === index}
              onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQ
