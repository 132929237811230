import React from "react";
import Image from 'next/future/image'

export default function InspiringReads() {
  return (
    <section className="relative pt-4">
      <div className="container mx-auto px-4">
        <div className="mb-16">
          <h2 className="text-3xl font-heading text-gray-800">Inspiring Reads</h2>
          <p className="text-gray-600 mt-4 md:w-1/2">
            Our top articles for mood board creation and inspiration.
          </p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-4 text-gray-700">
          <div className="flex flex-col items-start">
            <a href="https://moodboardai.com/blog/posts/designing-your-dream-kitchen-with-ai">
              <Image
                className="w-full mb-2"
                src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Designing-Your-Dream-Kitchen-with-MyRoomDesignerAI.png"
                alt="Designing Your Dream Kitchen With AI"
                width={500}
                height={300}
                layout="responsive"
              />
              <span className="font-heading text-gray-900 capitalize">
                Designing Your Dream Kitchen With AI
              </span>
            </a>
          </div>
          <div className="flex flex-col items-start">
            <a href="https://moodboardai.com/blog/posts/the-best-mood-board-creator">
              <Image
                className="w-full mb-2"
                src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/The-Best-Mood-Board-Creator.png"
                alt="The Best Mood Board Creator"
                width={500}
                height={300}
                layout="responsive"
              />
              <span className="font-heading text-gray-900 capitalize">
                The Best Mood Board Creator
              </span>
            </a>
          </div>
          <div className="flex flex-col items-start">
            <a href="https://moodboardai.com/blog/posts/designing-a-room-with-the-power-of-ai">
              <Image
                className="w-full mb-2"
                src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Designing-a-room-with-AI.png"
                alt="Designing A Room With The Power Of AI"
                width={500}
                height={300}
                layout="responsive"
              />
              <span className="font-heading text-gray-900 capitalize">
                Designing A Room With The Power Of AI
              </span>
            </a>
          </div>
          <div className="flex flex-col items-start">
            <a href="https://moodboardai.com/blog/posts/mood-board-creation-with-ai">
              <Image
                className="w-full mb-2"
                src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Living-Room-Mood-Board-Creation-with-Mood-Board-AI.png"
                alt="Mood Board Creation With AI"
                width={500}
                height={300}
                layout="responsive"
              />
              <span className="font-heading text-gray-900 capitalize">
                Mood Board Creation With AI
              </span>
            </a>
          </div>
          <div className="flex flex-col items-start">
            <a href="https://moodboardai.com/the-3-best-ways-to-find-interior-design-inspiration">
              <Image
                className="w-full mb-2"
                src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/1726671352492015.jpg"
                alt="The 3 Best Ways to Find Interior Design Inspiration"
                width={500}
                height={300}
                layout="responsive"
              />
              <span className="font-heading text-gray-900 capitalize max-w-xs">
                The 3 best ways to find interior design inspiration
              </span>
            </a>
          </div>
          <div className="flex flex-col items-start">
            <a href="https://moodboardai.com/how-to-create-a-mood-board">
              <Image
                className="w-full mb-2"
                src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/How-to-Create-A-Mood-Board.png"
                alt="How to Create A Mood Board and Design Your Room"
                width={500}
                height={300}
                layout="responsive"
              />
              <span className="font-heading text-gray-900 capitalize max-w-xs">
                How to Create A Mood Board and Design Your Room
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}