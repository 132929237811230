import React from "react"
import FAQ from "./Faq"

export default function MoodBoardFAQ() {
  const moodBoardFaqs = [
    {
      question: "What areas can I generate mood boards for with Mood Board AI?",
      answer:
        "Mood Board AI allows you to create mood boards for a wide range of areas, including interior design, fashion, wedding design, food branding, food photography, restaurant photography, kitchen design, living rooms, dining rooms, bedrooms, gardens, yoga studios, and more. Simply upload your images, and the AI will help curate the perfect visual inspiration for your project.",
    },
    {
      question:
        "What types of rooms can I design mood boards for using Mood Board AI?",
      answer:
        "You can design mood boards for various spaces including: Interior Design, Living Room, Dining Room, Bedroom, Kitchen, Bathroom, Home Office, Library, Nursery, Kids Room, Yoga Studio, Garden and more.",
    },
    {
      question: "How do I create the perfect mood board with Mood Board AI?",
      answer:
        '1. Begin by choosing your creative focus, whether you\'re designing a room, curating fashion looks, or planning a wedding. 2. Choose from design aesthetics like contemporary, vintage, or minimalist to set the tone for your mood board. 3. Add specific elements like plants, curves, or a fairytale theme to make your design unique. 4. Pick your favorite from four suggested boards, upscale your selected image for higher resolution, or create similar options. 5. Effortlessly refine your AI-generated design with the "Clean Up and Replace" feature. 6. Get tailored product suggestions that perfectly match the style and theme of your mood board.',
    },
    {
      question: "Can I further customize the design with specific elements?",
      answer:
        'Yes! You can add extra wishes and specific elements you want in your design, whether it\'s specific furniture pieces, colors, or additional details like plants or fairytale elements. Simply write them down and click the "Change prompt" button to update your design accordingly.',
    },
    {
      question: "Can I refine the AI-generated design?",
      answer:
        'Yes! You can use Mood Board AI\'s intuitive "Clean Up and Replace" feature to quickly refine the design, removing unwanted elements or making changes to better suit your vision. Additionally, you can upscale the selected image to a high resolution for enhanced photo quality, ensuring you have the best visual representation of your design. You can also add your AI-generated photo to the Mood Board Editor for further customization and create a shopping list for that design.',
    },
    {
      question:
        "How can I use the Mood Board AI to find products for my design?",
      answer:
        "Yes, you can! After generating your mood board, simply click the 'Find similar products' button. By selecting items in your mood board photo, you can discover real-life products that match the style.",
    },
    {
      question: "How can interior designers use this tool for their projects?",
      answer:
        "With MyRoomDesigner.AI, MoodBoard AI, and Board Editor, interior designers can easily create multiple design concepts, mood boards, shopping lists, and color palettes. The tool also allows for the creation of client-ready presentations, helping designers enhance their creative process, streamline their workflow, and present polished, professional designs to clients.",
    },
  ]

  return (
    <div className="container mx-auto px-5" id="faq-moodboard">
      <FAQ faqs={moodBoardFaqs} />
    </div>
  )
}
