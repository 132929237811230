import React, { useState } from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Logo from "./BoardLogo"
import MyRoomDesignerFooter from "./MyRoomDesignerFooter"
import InspiringReads from './InspiringReads'
import { LinkListComponent as LinkList } from "../pages/ai-generator/[[...slug]]"


const myRoomDesignerDomains = ["myroomdesigner.ai", "www.myroomdesigner.ai"]

function checkIsMyRoomDesignerAI(path) {
  if (typeof window !== "undefined") {
    return (
      myRoomDesignerDomains.includes(window.location.hostname) ||
      path.startsWith("/interior-design-ai")
    )
  }
  return path.startsWith("/interior-design-ai")
}

const FooterSection = ({
  title,
  links,
  isOpen,
  onToggle,
  variant = "default",
}) => {
  const widthClasses = {
    default: "lg:w-2/12",
    wide: "lg:w-3/12",
  }

  return (
    <div className={`w-full md:w-1/2 ${widthClasses[variant]} px-6`}>
      <div className="h-full">
        <button
          onClick={onToggle}
          className="flex lg:hidden justify-between w-full tracking-wide text-xs focus:outline-none font-heading text-gray-500 border-t border-gray-200 py-4"
        >
          {title}
          <span
            className={`transform transition-transform text-gray-500 ${
              isOpen ? "rotate-0" : "rotate-180"
            }`}
          >
            {isOpen ? "-" : "+"}
          </span>
        </button>
        <h3 className="mb-9 hidden lg:block font-heading font-semibold text-xs text-gray-500 uppercase tracking-px">
          {title}
        </h3>
        <ul
          className={`pl-4 lg:pl-0 lg:mt-0 space-y-2 lg:space-y-4 transition-all duration-300 ease-in-out overflow-hidden ${
            isOpen
              ? "max-h-[500px] opacity-100 mt-2 mb-4"
              : "max-h-0 opacity-0 mt-0 mb-0 lg:max-h-[500px] lg:opacity-100 lg:mt-2 lg:mb-4"
          } ${title === "Legals" ? "md:mr-14" : ""}`}
        >
          {links.map((link, index) => (
            <li key={index} className={link.extraClasses || ""}>
              <a
                href={link.href}
                className={
                  link.isPink
                    ? "font-heading text-base text-pink-700 hover:text-pink-500 bg-pink-50"
                    : "font-heading text-base text-gray-900 hover:text-gray-700"
                }
                rel={link.rel}
              >
                {link.text}
                {link.badge && (
                  <span className="ml-2 font-heading font-medium text-base text-pink-700 hover:text-pink-500 bg-pink-50 rounded-full px-2 py-0.5">
                    {link.badge}
                  </span>
                )}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default function Footer() {
  const router = useRouter()
  const { t } = useTranslation()
  const isMyRoomDesignerAI = checkIsMyRoomDesignerAI(router.asPath)
  const [companyOpen, setCompanyOpen] = useState(false)
  const [supportOpen, setSupportOpen] = useState(false)
  const [legalOpen, setLegalOpen] = useState(false)

  if (isMyRoomDesignerAI) {
    return <MyRoomDesignerFooter />
  }

  const footerSections = {
    company: {
      title: "Company",
      links: [
        {
          text: "Features",
          href:
            "https://docs.moodboardly.com/learn-about-the-ai-powered-mood-board-creator",
        },
        { text: "Pricing", href: "/pricing" },
        { text: "About Us", href: "/#about-us" },
        {
          text: "Free Color Palette Generator",
          href: "/color-palette-generator",
          badge: "New",
        },
      ],
    },
    support: {
      title: "Support",
      links: [
        { text: "Account", href: "/dashboard" },
        {
          text: "FAQ",
          href: "/#faq-moodboard",
        },
        { text: "Browse Ideas", href: "/ai" },
        { text: "Mood Boards", href: "/boards" },
      ],
    },
    legal: {
      title: "Legals",
      links: [
        { text: "Terms & Conditions", href: "/terms" },
        { text: "Privacy Policy", href: "/privacy" },
        {
          text: "Save up to 25% with our Annual Plans.",
          href: "/pricing",
          isPink: true,
          extraClasses: "md:pt-4",
        },
      ],
    },
  }

  return (
    <footer className="text-gray-600 mt-12">
      <InspiringReads />
      <LinkList />
      <section className="relative py-24 overflow-hidden">
        <div className="relative z-10 container mx-auto px-4 md:mt-6">
          <div className="flex flex-wrap -m-6 border-t border-gray-200 md:pt-6 md:pt-8">
            {/* Logo and Description */}
            <div className="w-full md:w-1/2 lg:w-5/12 p-6">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <Logo className="w-14 mb-12" />
                </div>
                <div>
                  <p className="mb-4 font-heading font-medium text-base">
                    MyRoomDesigner.AI ⎜ MoodBoard AI ⎜ Board Editor
                  </p>
                  <p className="text-gray-600 text-sm">
                    © {new Date().getFullYear()} - MoodBoardly. All Rights
                    Reserved
                  </p>
                </div>
              </div>
            </div>

            {/* Footer Sections */}
            <FooterSection
              {...footerSections.company}
              isOpen={companyOpen}
              onToggle={() => setCompanyOpen(!companyOpen)}
            />
            <FooterSection
              {...footerSections.support}
              isOpen={supportOpen}
              onToggle={() => setSupportOpen(!supportOpen)}
            />
            <FooterSection
              {...footerSections.legal}
              isOpen={legalOpen}
              onToggle={() => setLegalOpen(!legalOpen)}
              variant="wide"
            />
          </div>
        </div>
      </section>
    </footer>
  )
}
